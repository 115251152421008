<template>
  <div>
    <div class="homeView1" @click="thingsInter">
      <img class="nameImg" src="../assets/base/thingsInter.png" alt="" width="120" height="114">
     <div> 农业物联网</div>
    </div>
    <div class="homeView2" @click="erp">
      <img class="nameImg" src="../assets/base/ERP.png" alt="" width="115" height="120">
      <div> 农场ERP</div>
    </div>
    <div class="homeView3" @click="base">
      <img class="nameImg" src="../assets/base/base.png" alt="" width="120" height="120">
      <div> 基础信息</div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      mobile: this.$store.state.user.mobile,
    }
  },
  mounted() {
    console.log(localStorage.getItem('changeNavHead'), 'wwwwwwwwww122222222***********************');
  },
  methods:{
    thingsInter(){
      // this .$store.dispatch( 'changeNavHead' ,  'thingsInterner')
      localStorage.setItem('changeNavHead','thingsInterner')
      localStorage.setItem('url_wuliawnag','/index')
      this.$router.push('/index')
    },
    erp(){
      // this .$store.dispatch( 'changeNavHead' ,  'erp')
      localStorage.setItem('selectIndex','1')
      localStorage.setItem('changeNavHead','erp')
      localStorage.setItem('url_wuliawnag','/blankPage')
      this.$router.push('/blankPage')
    },
    base(){
      // this .$store.dispatch( 'changeNavHead' ,  'base')
      localStorage.setItem('changeNavHead','base')
      localStorage.setItem('url_wuliawnag','/blankPage')
      this.$router.push('/blankPage')
    },
  }
}
</script>

<style scoped>
.homeView1, .homeView2, .homeView3 {
  font-size: 40px;
  width: 360px;
  height: 360px;
  display: inline-block;
  margin-top: 280px;
  /*line-height: 360px;*/
  text-align: center;
  color: #FFFFFF;
  border-radius: 12px;
  cursor: pointer;
}

.homeView1 {
  background: #9145E0;
}

.homeView2 {
  background: #48B2E4;
  margin-left: 60px;
}

.homeView3 {
  background: #FCA238;
  margin-left:60px ;
}
.nameImg{
  vertical-align: middle;
  margin-top: 92px;
}
</style>
